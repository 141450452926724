import React, { Component, lazy } from 'react';
import { ThemeProvider } from 'styled-components/macro';

import theme from 'Theme/Theme';
import GlobalStyle from 'Theme/GlobalStyle';
import Loader from 'components/Loader/Loader';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
// import Quiz from 'views/Quiz/Quiz';
import withClearCache from 'helpers/ClearCache';
import { getBuildDate } from 'helpers/utils';
import LogRocket from 'logrocket';
import { componentLoader } from 'helpers/componentLoader';


const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident/');
  return (msie > 0 || trident > 0);
}


i18next.init({
  interpolation: { escapeValue: false },
});

if (isIE()) {
  window.location = `microsoft-edge:${window.location}`
}


const Quiz = lazy(() => componentLoader(() => import('views/Quiz/Quiz')), 5);

class Root extends Component {
  constructor(props) {
    super(props);
    this.RootRef = React.createRef();
    this.state = { rootRef: null };
  }

  componentDidMount() {
    this.setState({ rootRef: this.RootRef.current });
  }

  render() {
    const { rootRef } = this.state;
    console.log('build date: ', getBuildDate());
    return (
      <div ref={this.rootRef}>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <I18nextProvider i18n={i18next}>
            <React.Suspense fallback={<Loader />}>
              <Quiz rootRef={rootRef} />
            </React.Suspense>
          </I18nextProvider>
        </ThemeProvider>
      </div>
    );
  }
}

export default withClearCache(Root);
