import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyle = createGlobalStyle`

#root {
  /* background: linear-gradient(to right,#2e2d41,#110c23); */
  /* background: red; */
  /* height: 100vh; */

}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html { 
  font-size:62.5%
}

body {
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2e2d41;
  cursor: default;
}


button,
input {
  font-family: 'Raleway', sans-serif;
  background-color: transparent;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

button,
div{
  outline: none;
}
a, img, div{
  -webkit-tap-highlight-color: transparent;
}

input[type="text"]:focus + label[placeholder]:before,
input[type="text"]:valid + label[placeholder]:before {
          transition-duration: 0.2s;
          transform: translate(10px, -1.5em) scale(0.9, 0.9);
}
input[type="text"]:invalid + label[placeholder][alt]:before {
  content: attr(alt);
}
input[type="text"] + label[placeholder] {
  display: block;
  pointer-events: none;
  line-height: 1.25em;
  margin-top: calc(-3em - 2px);
  margin-bottom: calc((3em - 1em) + 2px);
}
input[type="text"] + label[placeholder]:before {
  content: attr(placeholder);
  display: inline-block;
  margin: 0 calc(1em + 2px);
  padding: 0 2px;
  white-space: nowrap;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
  background-image: linear-gradient(to bottom, #fff, #fff);
  background-size: 100% 5px;
  background-repeat: no-repeat;
  background-position: center;
}
input:-internal-autofill-selected{
  background-color: white;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

// slide rotete cadrd aniamtion
.c {
  position: absolute;
  will-change: transform, opacity;
}

// slick dot

.slick-dots > .slick-active > div {
  background-image: linear-gradient(45deg,rgb(187,206,63) 10%, rgb(146,186,67)100%);
}


.react-heading {
  font-size: 18px;
  font-weight: 700;
}

`;


export default GlobalStyle;
